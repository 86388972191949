/* --greenlist-left: rgba(204, 255, 21, 1);
--greenlist-right: rgba(104, 255, 0, 0.6); */
:root {
    --ul-li-left: #e3b0ff;
    --ul-li-right: #7072ff;
    --quote-top: #3acfd542;
    --quote-bottom: #3acfd542;
    --comment-top: blue;
    --comment-bottom: brown;
    --source-indicator-top: rgba(255, 203, 0, 0.6);
    --source-indicator-bottom: hsl(21 100% 50% / 0.3);
    --greenlist-left: #EBFACF;
    --greenlist-right: #DFF5E6;
    --redlist-left: #ff000012;
    --redlist-right: hwb(328.24deg 0% 0% / 8%);
    --bible-quote: hsl(182 100% 24% / 1);
    --jesus-quote: hsl(346deg 100% 31% / 79%);
    --comment-indicator-one: rgba(204, 255, 21, 1);
    --comment-indicator-two: rgba(104, 255, 0, 0.6);
    --blog-link-color: var(--app-link-color);
    --blog-link-decoration-color: var(--app-link-decoration-color);
    --highlight-referenced: rgba(255, 0, 0, 0.116);
}  

.SoftBlogColors {
    --ul-li-left: #ECDDFE;
    --ul-li-right: #2C72E4;
    /* --quote-top: #E6E8FF;
    --quote-bottom: #E6E8FF; */
    --quote-top: #B3C8FF;
    --quote-bottom: #B3C8FF;
    --comment-top: blue;
    --comment-bottom: rgb(255, 0, 0);
    --source-indicator-top: rgb(159, 159, 159);
    --source-indicator-bottom: rgb(159, 159, 159);
    --greenlist-left: #EBFACF;
    --greenlist-right: #DFF5E6;
    --redlist-left: #F8E5E7;
    --redlist-right: #F8E5E7;
    --bible-quote: #3E6399;
    --jesus-quote: #A73212;
    --comment-indicator-one: rgb(159, 159, 159);
    --comment-indicator-two: rgb(159, 159, 159);
    /* this looks great */
    /* --blog-link-color: #54759B; */
    --blog-link-color: #4F73BF;
    --blog-link-decoration-color: #54759B8A;
    --highlight-referenced: #FACE7845;
}

/* .StandardInlineHeader {
    background: #DAEAE0;
    color: #1B201E;
    padding: 3px;
    border-radius: 3px;
    padding-right: 6px;
    padding-left: 6px;
} */

.BlogNotesSection li {
    /* padding-bottom: ; */
}

a {
    color: var(--blog-link-color);
    text-decoration-color: var(--blog-link-decoration-color);
}

li {
    /* margin-top: 0px; */
    /* padding-bottom: 0px !important; */
}

.YellowHeader {
    /* background: linear-gradient(45deg, hsl(76deg 100% 50% / 16%), hwb(113deg 0% 0% / 9%)); */
    background: linear-gradient(45deg, var(--greenlist-left), var(--greenlist-right));
    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 6px;
    border-radius: 3px;
    padding-right: 5px;
    margin-left: -6px;
    font-variation-settings: "wght" 600;
    font-weight: 600;
}

.BigLiMarker .MuiTypography-body1 {
    padding-bottom: 0px !important;
}

.NegativeListLi {
    background: linear-gradient(45deg, var(--redlist-left), var(--redlist-right));
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 6px;
    border-radius: 3px;
    margin-left: -1.2em !important;
}

ol li::before {
    background: #0000000f;
    /* background: linear-gradient(163deg, #efd5ff 0%, #515ada 100%); */
    /* background: linear-gradient(163deg, #9045be99 0%, #4a52c2e5 100%); */
    background: linear-gradient(163deg, var(--ul-li-left) 8%, var(--ul-li-right) 92%);
    opacity: .9;
    /* background: linear-gradient(140deg, #fcff9e 0%, #c67700 100%); */
    color: white;
    content: counter(ol-step-counter);
    flex-shrink: 0;
    justify-content: center;
    margin-left: -2.2em;
    height: 1.6em;
    width: 1.6em;
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    line-height: 1.55;
}

.Articulos_Cards_Wrapper {
    /* grid-template-columns: repeat(1, 1fr) */
    display: grid;
    gap: 12px;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 550px) {
    .Articulos_Cards_Wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 550px) {
    .Articulos_Cards_Wrapper {
        grid-template-columns: repeat(2, 1fr)
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .Articulos_Cards_Wrapper {
        grid-template-columns: repeat(3, 1fr)
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .Articulos_Cards_Wrapper {
        grid-template-columns: repeat(4, 1fr)
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 /* @media only screen and (min-width: 1200px) {

 }  */

.InlineQuoteStyle {
    font-style: italic;
    font-size: 1.05em;
    font-variation-settings: "wght" 300;
    /* font-family: 'Spline Sans Variable Italic'; */
    /* opacity: 0.9; */
}
  
.BlogQuote {
    font-style: italic;
    font-variation-settings: "wght" 300;
}

.BlogQuote {
    word-spacing: initial !important;
}
  
.JesusQuoteStyle {
    /* color: #a80000; */
    /* color: #a80000db; */
    /* color: #a80000d6; */
    /* color: #a60000c9; */
    /* color: hsl(9deg 100% 33% / 79%); */
    /* color: hsl(-14deg 100% 33% / 79%); */
    color: var(--jesus-quote);
}
  
.BibleQuoteStyle {
    /* color: rgb(67, 69, 159); */
    color: var(--bible-quote);
    /* color: #00029bde; */
    /* color: rgb(62, 63, 142); */
    /* text-decoration-thickness: 2px;
    text-decoration-style: solid;
    text-decoration: blink;
    text-decoration-line: underline; */
    /* text-decoration-color: #8a2be230; */
    /* text-decoration-color: rgb(67, 69, 159);
    background-color: rgb(67, 69, 159); */
}

.VN {
    opacity: .4;
}

.InlineHeader {
    font-variation-settings: "wght" 600;
    font-weight: 600;
    /* background: #DAEAE0;
    color: #1B201E; */
    background: #F9F2FA;
    color: #2E0B08;
    padding: 3px;
    border-radius: 3px;
    padding-right: 6px;
    padding-left: 6px;
}

.InlineHeaderSecond {
    font-variation-settings: "wght" 600;
    font-weight: 600;
    /* background: #DAEAE0;
    color: #1B201E; */
    /* background: #F1F1F1;
    color: #000000; */
    background: #ECEFE87A;
    color: #383E31;
    padding: 3px;
    border-radius: 3px;
    padding-right: 6px;
    padding-left: 6px;
}